@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

img {
  max-width: 100%;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

h1 {
  font-size: 40px !important;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

h2 {
  font-size: 48px !important;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

h3 {
  font-size: 36px !important;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

h4 {
  font-size: 25px !important;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

h5 {
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

h6 {
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

label {
  font-size: 14px !important;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #0e393a;
  margin-bottom: 7px !important;
  text-transform: capitalize;
}

.circle_div div {
  width: 100% !important;
}

.circle_div {
  border-radius: 100%;
  height: 300px;
  width: 300px;
  margin: auto;
  padding: 10px 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* background-color: peru; */
}

.circle_div p {
  margin: 0;
}

.weather_forcast .d-flex.col-md-12 div {
  width: 50%;
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
  height: auto !important;
}

.weather_forcast p {
  margin: 0;
}

.weather_forcast {
  margin-top: 19px;
}
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
select {
  font-size: 14px !important;
  color: #404040 !important;
  font-weight: 400 !important;
  font-family: "Open Sans", sans-serif !important;
  width: 100% !important;
  border-radius: 35px !important;
  padding: 15px 30px 15px 30px !important;
  margin-bottom: 20px !important;
  height: 55px !important;
  background-color: #ebebeb !important;
  border: none !important;
}

select {
  /* background-image: url("/src/assets/select_arrow.png") !important; */
  background-position: right 20px center !important;
  background-size: 10px 7px !important;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
select:focus {
  color: unset !important;
  background-color: #ebebeb !important;
  border-color: unset !important;
  box-shadow: none !important;
}

.inner_banner_title {
  /* background-color: red; */
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.green_btn {
  background-color: #0eb694 !important;
  color: #fff !important;
  display: block !important;
  text-align: center !important;
  text-decoration: none !important;
  border-radius: 50px !important;
  padding: 15.5px 58px !important;
  font-size: 16px !important;
  text-transform: uppercase;
  border: none !important;
  font-weight: 600 !important;
  letter-spacing: 1px;
}

.green_btn:hover,
.green_btn:focus {
  background-color: #011515 !important;
  color: #fff !important;
  box-shadow: none !important;
}

.border_btn {
  background-color: transparent !important;
  border: 1px solid #0eb694 !important;
  color: #0eb694 !important;
  display: block !important;
  text-align: center !important;
  text-decoration: none !important;
  border-radius: 50px !important;
  padding: 15.5px 58px !important;
  font-size: 16px !important;
  text-transform: uppercase;
  font-weight: 600 !important;
  letter-spacing: 1px;
}
.close_btn_modal {
  /* background-color: #e78200 !important; */
  /* font-size: 12px; */
  color: #fff !important;
  text-align: center !important;
  text-decoration: none !important;
  border-radius: 50px !important;
  padding: 10px 25px !important;
  /* font-size: 16px !important; */
  text-transform: uppercase;
  border: none !important;
  font-weight: 600 !important;
  letter-spacing: 1px;
  margin: 10 auto 0 auto;
  position: relative;
}

.border_btn:hover,
.border_btn:focus {
  background-color: #011515 !important;
  color: #fff !important;
  box-shadow: none !important;
  border: none;
}

/* globle css end */

/* fillter form section css start */
.filter {
  padding-bottom: 65px;
  /* background-color: #0e393a; */
  position: relative;
  /* padding-top: 102px; */
  /* margin-top: 80px; */
}

.filter:before {
  content: "";
  position: absolute;
  top: -124px;
  z-index: auto;
  left: 0;
  height: 141px;
  width: 100%;
  /* background-image: url("/src/assets/top_part_bg.png"); */
  background-repeat: no-repeat !important;
  background-size: 100% auto !important;
}

.filter_form {
  background-color: #fff;
  padding: 35px 50px;
  /* box-shadow: 0px 0px 8px 0px #ededed; */
  box-shadow: 0px -3px 6px 0px #eaeaea;
  border-radius: 15px;
  position: relative;
  margin-bottom: 50px;
  margin-top: -205px;
}

body.black .filter_form {
  box-shadow: none;
}

.filter_form .green_btn {
  position: absolute;
  margin: 0 auto;
  bottom: -25px;
  left: 0;
  right: 0;
  max-width: 182px;
}

.filter_form label {
  padding-left: 10px;
}

.filter_data .data_img {
  height: 70px;
  width: 60px;
  border-radius: 10px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  margin-left: 12px;
  padding: 10px;
}
.btn_store_buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.playstore,
.appstore {
  display: block;
  text-align: center;
}
/* .playstore,
.appstore {
  display: flex;
  height: inherit;
  align-items: center;
} */
.playstore .qr_code,
.appstore .qr_code {
  width: 110px;
  margin-top: 10px;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus,
.filter_data:focus-visible,
.btn_footer_appstore:focus-visible,
.btn_footer_playstore:focus-visible {
  border-color: #0a58ca !important;
  box-shadow: 0 0 0 0.25rem rgb(49 132 253) !important;
  outline: none !important;
}
.filter_data_api:focus-visible {
  border-color: #e78200 !important;
  box-shadow: 0 0 0 0.25rem rgb(231 130 0) !important;
  outline: none !important;
}
.filter_data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  background-color: #e78200;
  border-radius: 12px;
  padding: 10px;

  /* gap: 15px; */
  /* padding: 20px 0; */
  /* border-bottom: 2px solid #1f595a; */
  /* margin-bottom: 20px; */
  cursor: pointer;
  text-decoration: none;
}

.filter_data_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  background-color: #4eabf0;
  border-radius: 12px;
  padding: 10px;

  /* gap: 15px; */
  /* padding: 20px 0; */
  /* border-bottom: 2px solid #1f595a; */
  /* margin-bottom: 20px; */
  cursor: pointer;
}

.filter_data_api {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* width: 2; */
  height: 120px;
  background-color: #fff;
  border-radius: 12px;
  /* border-color: #011515; */
  border: 1px solid #272121;
  /* border: 0.1px solid #e1e1e1; */
  /* border-width: 0.1px; */
  /* gap: 15px; */
  /* padding: 20px 0; */
  /* border-bottom: 2px solid #1f595a; */
  /* margin-bottom: 20px; */
  cursor: pointer;
  text-decoration: none;
}

.filter_data_api .data_text_api {
  width: 58%;
  /* height: 20%; */
  position: relative;
}

.filter_data_api .data_text_api h6 {
  color: black;
  margin-bottom: 5px;
  transition: 0.5s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.filter_data .data_text {
  width: 58%;
  position: relative;
}

.filter_data .data_text h6 {
  color: #fff;
  margin-bottom: 5px;
  text-align: center;
  transition: 0.5s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.filter_data .data_text p {
  margin-bottom: 0;
  color: #fff;
  font-size: 14px;
}

.filter_data .data_text .arrow_icon {
  position: absolute;
  right: 0;
  bottom: 0px;
}

.filter_data .data_text .arrow_icon svg {
  color: #1f595a;
  font-size: 14px;
  transition: 0.5s;
}

.filter_data:hover h6,
.filter_data:hover .data_text .arrow_icon svg {
  color: #0eb694;
  transition: 0.5s;
}

.hover_box .data_img {
  height: 80px;
  width: 120px;
  border-radius: 10px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  box-shadow: 0px 0px 8px 2px #b4b4b4;
}

.hover_box {
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: none;
  margin-bottom: 20px;
}

.hover_box .data_text {
  width: 100%;
  position: relative;
}

.hover_box .data_text a {
  text-decoration: none;
}

.hover_box .data_text h6 {
  color: #0eb694;
  margin-bottom: 5px;
  transition: 0.5s;
}

.hover_box .data_text p {
  margin-bottom: 0;
  color: #0e393a;
  font-size: 14px;
}

.hover_box .data_text .arrow_icon {
  position: absolute;
  right: 0;
  top: -20px;
  color: #0eb694;
}

.hover_box .data_text .arrow_icon svg {
  color: #0eb694;
  font-size: 14px;
  transition: 0.5s;
}

.hover_box .data_text ul {
  padding: 0;
  margin: 0;
}

.hover_box .data_text ul li {
  list-style: none;
  display: inline-block;
  padding-right: 15px;
}

.hover_box .data_text ul li p span {
  color: #0eb694;
  margin-right: 8px;
  font-weight: 500;
  text-transform: capitalize;
}

.modal-content {
  border: none !important;
  border-radius: 15px !important;
}

p.description {
  font-size: 14px;
  line-height: 1.9;
  white-space: break-spaces;
}

.modal-body {
  padding: 30px !important;
  border-radius: 15px !important;
}

.hover_box .data_text h6:hover,
.hover_box .data_text .arrow_icon svg:hover {
  color: #0e393a;
}

.modal {
  padding: 0 !important;
}

/* fillter form section css end */

/* video section start */
.video_sec {
  padding: 100px 0 110px 0;
}

.video_sec .row {
  align-items: center;
}

.video_sec .row .video_div_light {
  position: relative;
}

.video_sec .row .video_div_dark {
  position: relative;
}

.video_sec .row .video_div_light:before {
  content: ". . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . ";
  font-weight: 900;
  font-size: 31px;
  height: 85px;
  width: 290px;
  position: absolute;
  line-height: 13px;
  top: -82px;
  left: 0;
  color: #e5e5e5;
}

.video_sec .row .video_div_light:after {
  content: ". . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . ";
  font-weight: 900;
  font-size: 31px;
  height: 85px;
  width: 290px;
  position: absolute;
  line-height: 13px;
  bottom: -82px;
  right: 0;
  color: #e5e5e5;
}

.video_sec .row .video_div_dark:before {
  content: ". . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . ";
  font-weight: 900;
  font-size: 31px;
  height: 85px;
  width: 290px;
  position: absolute;
  line-height: 13px;
  top: -82px;
  left: 0;
  color: #10393d;
}

.video_sec .row .video_div_dark:after {
  content: ". . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . ";
  font-weight: 900;
  font-size: 31px;
  height: 85px;
  width: 290px;
  position: absolute;
  line-height: 13px;
  bottom: -82px;
  right: 0;
  color: #10393d;
}

.clear_btn {
  background-color: #0eb694 !important;
  color: #fff !important;
  display: block !important;
  text-align: center !important;
  text-decoration: none !important;
  border-radius: 50px !important;
  /* padding: 15.5px 58px !important; */
  font-size: 16px !important;
  /* text-transform: uppercase; */
  border: none !important;
  font-weight: 600 !important;
  letter-spacing: 1px;
  float: right;
}

.clear_btn:hover,
.clear_btn:focus {
  background-color: #011515 !important;
  color: #fff !important;
  box-shadow: none !important;
}

.video_sec .react-player__preview {
  margin: 10px 0;
  border-radius: 15px;
}

.video_sec iframe {
  border-radius: 15px;
}

.video_text h6 {
  color: #0eb694;
  margin-bottom: 10px;
}

.video_text h2 {
  color: #0e393a;
  line-height: 1;
}

.video_text h2 + p {
  color: #0eb694 !important;
  margin-bottom: 25px;
}

.video_text p + p {
  max-width: 412px;
  color: #454545;
  font-size: 15px;
}

.border_btn {
  background-color: transparent !important;
  border: 1px solid #0eb694 !important;
  color: #0eb694 !important;
  display: block !important;
  text-align: center !important;
  text-decoration: none !important;
  border-radius: 50px !important;
  padding: 15.5px 58px !important;
  font-size: 16px !important;
  text-transform: uppercase;
  font-weight: 600 !important;
  letter-spacing: 1px;
  margin: 0 auto;
}

.border_btn:hover {
  background-color: #011515 !important;
  color: #fff !important;
  box-shadow: none !important;
  border: 1px solid #011515 !important;
}

.border_btn:focus {
  background-color: transparent !important;
  color: #fff !important;
  box-shadow: none !important;
}

/* video section end */

/* Newsletter Signup css start */
.newsletter {
  padding: 82px 0;
  text-align: center;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
}

.newsletter_form {
  max-width: 470px;
  margin: 30px auto 0 auto;
  background-color: #fff;
  border-radius: 35px;
}

.newsletter_form input[type="email"] {
  background-color: transparent !important;
  border: none !important;
  margin-bottom: 0 !important;
  padding: 15px 0 15px 30px !important;
}

.newsletter_form form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.newsletter_form button {
  background-color: transparent;
  border: none;
  color: #0eb694;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 16px;
  padding: 0 30px 0 25px;
  height: 55px;
  border-radius: 0;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
}

.newsletter_form button:hover,
.newsletter_form button:focus {
  background-color: #0eb694;
  color: #fff;
  box-shadow: none !important;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
}

.newsletter_form form div {
  width: 72%;
}

.loader {
  text-align: center;
}

body .text-dark {
  --bs-text-opacity: 1;
  color: #e78200 !important;
}

.error {
  text-align: center;
  color: #fff;
}

.newsletter h5 {
  color: #0eb694;
}

.newsletter h3 {
  color: #fff;
}

button.scroll-to-top {
  background-color: #8bd12a !important;
  border-radius: 100%;
  bottom: 52px;
  right: 20px;
}

button.scroll-to-top svg {
  color: #fff;
  margin-top: -3px;
}

/* Newsletter Signup end*/

/* error page */

.error_page {
  position: absolute;

  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

/* responsive start */
@media screen and (max-width: 1024px) and (min-width: 991px) {
  .logo_number p {
    font-size: 20px;
  }

  .filter:before {
    top: -85px;
  }

  h2 {
    font-size: 40px !important;
  }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  h1 {
    font-size: 30px !important;
  }

  h2 {
    font-size: 26px !important;
  }

  h3 {
    font-size: 26px !important;
  }

  .newsletter_form {
    margin: 15px auto 0 auto;
  }

  .filter:before {
    display: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 300px) {
  h1 {
    font-size: 20px !important;
  }

  h2 {
    font-size: 24px !important;
    line-height: 1.2 !important;
  }

  h3 {
    font-size: 17px !important;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 18px;
  }

  body {
    font-size: 14px;
  }

  .inner_banner {
    height: 195px;
  }

  .filter_form {
    padding: 20px 10px;
  }

  .filter {
    padding-bottom: 20px;
  }

  .video_sec {
    padding: 45px 0 45px 0;
  }

  .video_sec .row .video_div_light:before,
  .video_sec .row .video_div_light:after {
    display: none;
  }

  .video_sec .row .video_div_dark:before,
  .video_sec .row .video_div_dark:after {
    display: none;
  }

  .video_text h2 + p {
    margin-bottom: 15px;
  }

  .newsletter {
    padding: 40px 0;
  }

  .newsletter_form {
    margin: 10px auto 0 auto;
  }

  .filter:before {
    display: none;
  }

  .hover_box {
    flex-wrap: wrap;
  }

  .footer_link_text_p {
    text-align: left !important;
  }

  .modal.show .modal-dialog {
    margin: 25px 25px;
  }

  .hover_box .data_img {
    height: 196px;
    width: 262px;
    margin-bottom: 20px;
  }

  .api_value_box .col-lg-3.col-xs-6 {
    width: 50%;
    max-width: 50%;
  }
  .filter .col-lg-4.col-md-3 {
    width: 33.333%;
    max-width: 33.333%;
    padding: 4px;
  }
  .filter_data .data_text {
    width: 88%;
    position: relative;
  }
}

/* responsive end */
