@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

.header {
  padding: 30px 0 0 0;
}

nav.navbar.navbar-expand-lg.navbar-light {
  padding: 0;
  justify-content: space-between;
}

nav.navbar.navbar-expand-lg.navbar-light a.nav-link {
  font-size: 15px;
  text-transform: capitalize;
  color: #0e393a;
  margin: 0 10px;
  font-family: "Open Sans", sans-serif;
}

.icon_box {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-left: 10px;
}

.icon_box_two {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-left: 10px;
}

.help_btn {
  background-color: #e78200 !important;
  color: #fff !important;
  display: block !important;
  text-align: center !important;
  text-decoration: none !important;
  border-radius: 50px !important;
  padding: 5px 20px !important;
  font-size: 16px !important;
  text-transform: uppercase;
  border: none !important;
  font-weight: 600 !important;
  letter-spacing: 1px;
  /* margin: auto; */
}

.green_btn_local_weather {
  background-color: #8bd12a !important;
  font-size: 12px;
  color: #fff !important;
  display: block !important;
  text-align: center !important;
  text-decoration: none !important;
  border-radius: 50px !important;
  padding: 12.5px 45px !important;
  font-size: 16px !important;
  text-transform: uppercase;
  border: none !important;
  font-weight: 600 !important;
  letter-spacing: 1px;
  margin: 10 auto 0 auto;
  position: relative;
  z-index: 9999999999999999;
}

.green_btn_local_weather_2 {
  /* background-color: #8bd12a; */
  font-size: 12px;
  color: #fff !important;
  text-align: center !important;
  text-decoration: none !important;
  border-radius: 50px !important;
  padding: 12.5px 45px !important;
  font-size: 16px !important;
  text-transform: uppercase;
  border: none !important;
  font-weight: 600 !important;
  letter-spacing: 1px;
  margin: 10 auto 0 auto;
  position: relative;
  /* z-index: 9999999999999999; */
}

.green_btn_local_weather_3 {
  /* background-color: #e78200 !important; */
  font-size: 12px;
  color: #fff !important;
  text-align: center !important;
  text-decoration: none !important;
  border-radius: 50px !important;
  padding: 12.5px 45px !important;
  font-size: 16px !important;
  text-transform: uppercase;
  border: none !important;
  font-weight: 600 !important;
  letter-spacing: 1px;
  margin: 10 auto 0 auto;
  position: relative;
  z-index: 9999999999999999;
}

.green_btn_local_weather p {
  font-size: 12px;
  color: #fff !important;

  padding-top: 50;
}

.righ_nav_items {
  flex-direction: column !important;
}

.green_btn_local_weather:hover,
.green_btn_local_weather:focus {
  background-color: #648633 !important;
  color: #fff !important;
  box-shadow: none !important;
}

.icon_box a:nth-child(1) {
  background-color: #0eb694;
  border-radius: 100%;
  height: 32px;
  width: 32px;
  text-align: center;
  line-height: 32px;
  font-size: 13px;
}

.icon_box a svg {
  color: #fff;
}

.icon_box p {
  margin-bottom: 0;
  color: #0e393a;
  text-transform: capitalize;
  font-size: 13px;
  display: flex;
  align-items: center;
  font-family: "Open Sans", sans-serif;
}

.icon_box p button.toggle_button img {
  display: none;
}

.icon_box p button.toggle_button {
  height: 16px;
  width: 16px;
  font-size: 20px;
  display: block;
  margin-left: 10px;
  position: relative;
  background-color: #fff;
  border-radius: 100%;
}

.icon_box p button.toggle_button_light {
  background-color: #103a39;
}

.icon_box p button.toggle_button:before {
  /* content: ""; */
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  background-color: #0eb694;
  height: 6px;
  width: 6px;
  border-radius: 100%;
  line-height: 6px;
  text-align: center;
}

.icon_box p .dark {
  background-color: #ffffff;
}

.icon_box p .light {
  background-color: #0e393a;
}

.header_text {
  text-align: center;
  max-width: 747px;
  margin: auto;
}

.inner_banner {
  height: 80px;
  display: flex;
  /* margin-top: 50px; */
  align-items: center;
}

.inner_banner h1 {
  color: #000000;
}

header a:hover {
  color: #0eb694 !important;
}

body.black .navbar-light .navbar-toggler-icon {
  /* background-image: url("/src/assets/hamburger.png"); */
}

.header_logo_image {
  height: 100px;
  width: 100px;
  background-size: contain !important;
  background-position: center center !important;
}

@media screen and (max-width: 1024px) and (min-width: 991px) {
  .header_logo_image,
  .loader {
    margin-left: 52px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .navbar {
    flex-wrap: nowrap;
  }

  .navbar-light .navbar-toggler {
    border-color: transparent !important;
    box-shadow: none !important;
  }

  div#basic-navbar-nav {
    position: absolute;
    top: 65px;
    background-color: #fff;
    max-width: 100%;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 9px #0eb69436;
    z-index: 99;
  }

  body.black div#basic-navbar-nav {
    background-color: #0eb694;
  }

  body.black header a:hover {
    color: #011515 !important;
  }
  .header_logo_image,
  .loader {
    margin-left: 32px;
  }
}

@media screen and (max-width: 767px) and (min-width: 300px) {
  .navbar {
    flex-wrap: nowrap;
  }

  .navbar-light .navbar-toggler {
    border-color: transparent !important;
    box-shadow: none !important;
  }

  div#basic-navbar-nav {
    position: absolute;
    top: 61px;
    background-color: #fff;
    max-width: 100%;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 9px #0eb69436;
    z-index: 99;
  }

  body.black div#basic-navbar-nav {
    background-color: #0eb694;
  }

  body.black header a:hover {
    color: #011515 !important;
  }
  .header_logo_image,
  .loader {
    margin-left: 32px;
  }
}
