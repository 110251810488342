@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

footer {
  padding: 60px 0 30px 0;
  /* background-color: #f2f2f2; */
}

.logo_number {
  display: flex;
  align-items: center;
  /* gap: 50px; */
  flex-wrap: wrap;
}

.btn_footer_playstore {
  /* background-color: #8bd12a !important; */
  color: #fff !important;
  display: block !important;
  text-align: center !important;
  text-decoration: none !important;
  border-radius: 12px !important;
  padding: 17px !important;
  font-size: 14px !important;
  text-transform: uppercase;
  border: none !important;
  width: 170px;
  font-weight: 600 !important;
  /* letter-spacing: 1px; */
}

.btn_footer_appstore {
  /* background-color: #e78200 !important; */
  color: #fff !important;
  display: block !important;
  text-align: center !important;
  text-decoration: none !important;
  border-radius: 12px !important;
  padding: 17px !important;
  width: 170px;
  font-size: 14px !important;
  text-transform: uppercase;
  border: none !important;
  font-weight: 600 !important;
  /* letter-spacing: 1px; */
}

.logo_number p {
  color: #404040;
  /* font-size: 25px; */
  margin-bottom: 0;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
}

.logo_number p a {
  color: #0eb694;
  text-decoration: none;
  font-weight: 700;
  margin-left: 10px;
}

ul.social_icon {
  padding-left: 0;
  list-style: none;
  margin: 0;
  text-align: right;
}

ul.social_icon li {
  display: inline-block;
  margin-left: 14px;
}

ul.social_icon li a {
  color: #606060;
  font-size: 18px;
}

ul.social_icon li a svg {
  transition: transform 0.5s;
}

ul.social_icon li a:hover svg {
  -ms-transform: scale(1.2);
  /* IE 9 */
  -webkit-transform: scale(1.2);
  /* Safari 3-8 */
  transform: scale(1.2);
}

ul.social_icon {
  padding-left: 0;
  list-style: none;
  margin: 0;
  text-align: right;
}

ul.social_icon li {
  display: inline-block;
  margin-left: 14px;
}

ul.social_icon li a {
  color: #606060;
  font-size: 18px;
}

ul.footer_menu {
  padding: 0;
  margin: 0;
}

ul.footer_menu li {
  list-style: none;
  display: inline-block;
  padding-right: 30px;
}

ul.footer_menu li a {
  text-decoration: none;
  font-size: 15px;
  color: #404040;
  text-transform: capitalize;
}

.othe_details {
  text-align: right;
}

.othe_details a {
  font-size: 14px;
  text-decoration: none;
  margin-left: 20px;
  color: #404040;
}

.othe_details a svg {
  margin-right: 5px;
  font-size: 14px;
  color: #0eb694;
  transition: transform 0.5s;
}

.othe_details a:hover svg {
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

footer .row:nth-child(2) {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 45px;
  margin-bottom: 45px;
  /* border-top: 1px solid #d3d2d2;
  border-bottom: 1px solid #d3d2d2; */
}

.light_border {
  border-top: 1px solid #d3d2d2;
  border-bottom: 1px solid #d3d2d2;
}

.dark_border {
  border-top: 1px solid #072d2e;
  border-bottom: 1px solid #072d2e;
}

.Copyright p {
  margin-bottom: 0;
  color: #404040;
}

.footer_link_text {
  text-decoration: none;
  color: rgb(255 255 255);
}

.footer_link_text_p {
  text-align: end;
}

ul.footer_menu.f_m_two {
  text-align: right;
}

ul.footer_menu.f_m_two li {
  padding-left: 30px;
  padding-right: 0;
  position: relative;
}

ul.footer_menu.f_m_two li a {
  font-size: 14px;
}

ul.footer_menu.f_m_two .footer_li_light:before {
  content: "";
  position: absolute;
  left: 13px;
  top: 53%;
  transform: translateY(-50%);
  height: 1px;
  width: 5px;
  background-color: #404040;
}

ul.footer_menu.f_m_two .footer_li_light:nth-child(1):before {
  display: none;
}

ul.footer_menu.f_m_two .footer_li_dark:before {
  content: "";
  position: absolute;
  left: 13px;
  top: 53%;
  transform: translateY(-50%);
  height: 1px;
  width: 5px;
  background-color: rgb(255 255 255);
}

ul.footer_menu.f_m_two .footer_li_dark:nth-child(1):before {
  display: none;
}

footer a:hover {
  color: #0eb694 !important;
}

.col-md-4 {
  margin-left: "12px";
  /* width: 20%; */
}

footer .row .col-md-6,
footer .row .col-md-8,
footer .row .col-md-4 {
  padding: 0;
}

a.footer_link_text {
  text-decoration: underline;
}

.footer_link_text_p a.footer_link_text img {
  width: 25px;
  margin-top: -4px;
  transition: transform 0.5s;
  margin-right: 5px;
}

.footer_link_text_p a.footer_link_text:hover img {
  transform: scale(1.2);
}

footer .row.light_border p {
  margin: 0 !important;
}

footer .row.dark_border p {
  margin-bottom: 0;
}

/* responsive start */
@media screen and (max-width: 991px) and (min-width: 1024px) {
  .logo_number {
    gap: 10px;
  }

  footer .row:nth-child(2) {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 35px;
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
}

@media screen and (max-width: 767px) and (min-width: 300px) {
  footer .row .col-md-6,
  footer .row .col-md-8,
  footer .row .col-md-4 {
    padding: 0 15px;
  }
  .btn_footer_appstore, .btn_footer_playstore {
    font-size: 12px!important;
    width: 130px;
    padding: 17px 10px!important;
    }
  .filter_data_api .data_text_api h6 {
    font-size: 18px;
    padding: 0 10px;
  }
  .filter_data_api .data_text_api {
    width: 100%;
  }

  /* .col-md-4 {
    padding-right: 20px;
    justify-content: center;
    align-items: center;
  } */
  .logo_number {
    justify-content: flex-start;
    gap: 10px;
  }

  .logo_number p {
    font-size: 16px;
  }

  ul.social_icon {
    text-align: left;
    margin-top: 5px;
  }

  footer .row:nth-child(2) {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  ul.footer_menu li {
    padding-right: 18px;
  }

  .othe_details {
    margin-top: 10px;
    text-align: left;
  }

  .othe_details a {
    margin-left: 0;
    display: block;
    margin-bottom: 5px;
  }

  ul.social_icon li {
    margin-left: 0;
    margin-right: 14px;
  }

  footer .row:nth-child(3) {
    flex-direction: column-reverse;
  }

  ul.footer_menu.f_m_two {
    text-align: left;
    margin-bottom: 10px;
  }

  ul.footer_menu.f_m_two li {
    padding-left: 0;
    padding-right: 25px;
  }

  .Copyright p {
    font-size: 12px;
  }

  .icon_box {
    gap: 10px;
  }

  footer {
    padding: 50px 0 30px 0;
  }
}

/* responsive end */
